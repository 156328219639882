<template>
<div class="page">
  <head-nav />
  <div class="inner-ban" :style="{ backgroundImage: 'url(' + banner + ')' }"></div>
  <div class="main">
    <div class="auto wrap flexLr">
      <Lefter :bg="menuBackground" @click="interflowFun"/>
      <!-- <div class="main-l">
        <div class="menu-titbox" :style="{ backgroundImage: 'url(' + menuBackground + ')' }">
          学术活动
        </div>
        <ul class="menu-list">
          <li :class="interflowIndex == item.typeCodeNum ? 'curBg' : ''" v-for="(item, index) in interflowLists" :key="index" @click="interflowFun(item)">
            {{ item.names }}
          </li>
        </ul>
      </div> -->
      <div class="main-r flex-1 bg-white">
        <div class="conbx" v-show="!dialogTableVisible">
          <div class="inner-tt">
            <span class="bt">{{interflowTitle}}</span>
          </div>
          <div class="main-wrap">
            <ul class="xsjl-list-t flex flex-w">
              <li class="item" v-for="(item, index) in interflowDetailsList" :key="index" @click="achievementDetailsFun(item)">
                <router-link to="" v-if="index<3">
                  <div class="imgbox zoomimg">
                    <img :src="
                              item.Thum == null || item.Thum[0] == undefined
                                ? tolerantImg
                                : apiUrl +
                                  '/Item/PreviewFile?fileName=' +
                                  encodeURIComponent(item.Thum[0])
                            " class="img" />
                  </div>
                  <h6 class="title ellipsis">{{ item.ItemName }}</h6>
                </router-link>
              </li>
            </ul>
            <ul class="kjcg-list">
              <li v-for="(item , index) in interflowDetailsList" :key="index" class="item flex flex-pack-justify" @click="achievementDetailsFun(item)">
                <h6 class="title ellipsis">
                  {{ item.ItemName }}
                </h6>
                <span class="date">{{
                        common.dateFormat(
                          "YYYY-mm-dd",
                          item.CrDate
                        )
                      }}</span>
              </li>
            </ul>

            <div class="pagelist" v-show="dataCount > 10">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 15, 20, 25]" :page-size="pageSize" :total="dataCount" layout="prev, pager, next">
              </el-pagination>
            </div>

          </div>
        </div>

        <div class="popbx" v-if="dialogTableVisible">
          <el-dialog :title="detailsTitle" :visible.sync="dialogTableVisible" :modal="false" class="new-dialog">
            <articleDetail ref='sonV' />
          </el-dialog>
        </div>
      </div>
    </div>
  </div>

  <foot />
</div>
</template>

<script>
import headNav from "@/components/head.vue";
import foot from "@/components/foot.vue";
import articleDetail from "@/components/article.vue";
import Lefter from "@/components/lefter.vue";

export default {
  name: "",
  data() {
    return {
      banner: require("@/assets/images/img_03.jpg"),
      menuBackground: require("@/assets/images/navBg09.jpg"),
      tolerantImg: require("@/assets/images/idx_02.jpg"),
      interflowIndex: 'CX020',
      interflowLists: [{
        names: '学术活动',
        rootIdNum: '10000',
        itemIdNum: '31',
        typeCodeNum: 'CX020',
      }],
      // 标题
      interflowTitle: "",
      rootIdNum: "",
      itemIdNum: "",
      typeCodeNum: "",
      interflowDetailsList: [],
      dialogTableVisible: false,
      detailsCentent: "",
      detailsTitle: '',

      //默认第一页
      currentPage: 1,
      //数据总条数
      dataCount: 0,
      pageSize: 10,
    };
  },
  components: {
    headNav,
    foot,
    articleDetail,
    Lefter
},
  watch: {
    $route(to, from) {
      if (this.$route.query.typeCode) {
        var typeCode = this.$route.query.typeCode;
        for (var index in this.interflowLists) {
          var item = this.interflowLists[index];
          if (item.typeCodeNum == typeCode) {
            this.interflowFun(item);
          }
          if (item.typeCodeNum == typeCode && this.$route.query.supItemId) {
            this.UrlDetailRedirect();
          }
        }
      } else {
        var item = this.interflowLists[0];
        this.interflowFun(item);
      }
    },
  },
  mounted() {
    let that = this;
    // 初始化加载奖励列表
    that.rootIdNum = that.interflowLists[0].rootIdNum
    that.itemIdNum = that.interflowLists[0].itemIdNum
    that.typeCodeNum = that.interflowLists[0].typeCodeNum
    that.send(
      that.rootIdNum,
      that.itemIdNum,
      that.typeCodeNum,
      that.currentPage
    );
    that.interflowTitle = that.interflowLists[0].names;
    that.UrlDetailRedirect();
  },
  methods: {
    interflowFun(item) {
      let that = this;
      that.dialogTableVisible = false;
      that.interflowIndex = item.typeCodeNum;
      that.interflowTitle = item.names;
      that.rootIdNum = item.rootIdNum;
      that.itemIdNum = item.itemIdNum;
      that.typeCodeNum = item.typeCodeNum
      that.send(that.rootIdNum, that.itemIdNum, that.typeCodeNum);
      document.documentElement.scrollTop = document.body.scrollTop = 0;
    },
    // 加载奖励列表
    send(rootIdNum, itemIdNum, typeCodeNum, currentPage) {
      let that = this;
      if (rootIdNum == "" && itemIdNum == "" && typeCodeNum == "") {
        rootIdNum = that.rootIdNum;
        itemIdNum = that.itemIdNum;
        typeCodeNum = that.typeCodeNum;
      }
      var obj = {
        rootId: rootIdNum,
        itemId: itemIdNum,
        typeCode: typeCodeNum,
        pageIndex: currentPage,
        pageSize: that.pageSize,
        DisableItemContent: true,
      };
      //   var obj = { rootId: '10000', itemId: '120', typeCode: 'CX032' };

      var centent = JSON.stringify(obj);

      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/Source/Item",
        data: centent,
        success: function(res) {
          //console.log(JSON.stringify(res.Data))
          var datefield = "CrDate";
          for (var index in res.Data.Cols) {
            var tmpModel = res.Data.Cols[index]
            if (tmpModel.title == "发布时间") {
              datefield = tmpModel.field;
            }
          }
          var datas = res.Data;
          that.interflowDetailsList = datas.Items;
          that.interflowDetailsList.sort((a, b) => new Date(b[datefield]).getTime() - new Date(a[datefield]).getTime()) //这是升序，倒序的话翻过来
          that.dataCount = res.Count;
        },
      });
    },
    handleSizeChange(val) {
      let that = this;
      that.pageSize = val;
      //console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      let that = this;
      // console.log(`当前页: ${val}`);
      that.send("", "", "", val);
    },
    // 奖励详情
    achievementDetailsFun(item) {
      let that = this;
      let detailsarr = '';
      var obj = {
        rootId: item.RootId,
        itemId: item.ItemId,
        typeCode: item.TypeCode,
        supItemIdNum: item.ParentId,
        userId: this.userToken,
      };

      var centent = JSON.stringify(obj);


      $.ajax({
        type: "post",
        contentType: "application/json",
        url: this.apiUrl + "/Api/ItemApi/EditDetail",
        data: centent,
        success: function(res) {
          //console.log(res.Data)
          detailsarr = res.Data.Attr
          that.$refs.sonV.childMethods(res, "/xueshujiaoliu");
          for (var i = 0; i < detailsarr.length; i++) {
            if (detailsarr[i].Source == 'ItemName') {
              that.detailsTitle = detailsarr[i].Value
            }
          }

        },
      });
      document.documentElement.scrollTop = document.body.scrollTop = 0;
      this.dialogTableVisible = true;
    },

    //地址详情页跳转
    UrlDetailRedirect() {
      let that = this;
      let rootIdNum = 10000;
      let itemIdNum = this.$route.query.itemId;
      let typeCodeNum = this.$route.query.typeCode;
      let supItemIdNum = this.$route.query.supItemId;
      let names = this.$route.query.name;
      if (!(itemIdNum > 0))
        return;
      var obj = {
        RootId: rootIdNum,
        ItemId: itemIdNum,
        TypeCode: typeCodeNum,
        ParentId: supItemIdNum,
      };
      that.interflowIndex = typeCodeNum;
      if (names != '' && names != undefined) {
        setTimeout(function() {
          that.interflowTitle = names
          that.send(rootIdNum, itemIdNum, typeCodeNum)
        }, 0)
        return
      }
      that.achievementDetailsFun(obj)

    },




  },
};
</script>

<style>

</style>
  